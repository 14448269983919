import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  MenuItem,
  InputBase,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { useDebounce, usePrevious } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import PlaylistListItem from './PlaylistListItem';
import FullCalendar from '@fullcalendar/react';
import { FULLCALENDAR_LICENSE_KEY, QUERY_KEYS } from 'constants/constants';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Draggable } from '@fullcalendar/interaction';
import { Playlist } from 'interfaces';
import { useGetPlaylists } from 'api/usePlaylist';
import { useQuery } from 'react-query';
import { SnackbarContext } from 'components/Snackbar/Snackbar';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { DataContext } from 'contexts';

function PlaylistList() {
  const { getPlaylists } = useGetPlaylists();
  const { createSnack } = useContext(SnackbarContext);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortBy, setSortBy] = useState<'id' | 'name'>('id');
  const { schedulerResourcesCount } = useContext(DataContext);
  const debouncedSearchPhrase = useDebounce(searchPhrase, 300);
  const previousSearchCriteria = usePrevious({
    debouncedSearchPhrase: '',
    sortBy: 'id'
  });

  const heightOffset = 438 + schedulerResourcesCount * 34;

  const { data, isLoading, isError, error } = useQuery<Playlist[], Error>(
    QUERY_KEYS.PLAYLISTS,
    getPlaylists
  );

  const playlists = data
    ?.filter((item) => {
      // User's search phrase
      if (debouncedSearchPhrase) {
        const regex = new RegExp(debouncedSearchPhrase, 'gi');
        return item.name.match(regex);
      }

      return true;
    })
    .sort((a, b) => {
      if (sortBy === 'name') {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }

      return a[sortBy] > b[sortBy] ? -1 : 1;
    });

  // Do lazy load check when search criteria changes
  useEffect(() => {
    if (
      previousSearchCriteria?.debouncedSearchPhrase !== debouncedSearchPhrase ||
      previousSearchCriteria?.sortBy !== sortBy
    ) {
      forceCheck();
    }
  }, [previousSearchCriteria, debouncedSearchPhrase, sortBy]);

  if (isError) {
    createSnack(`getPlaylists request failed! ${error?.message}`, {
      severity: 'error'
    });
    return null;
  }

  return (
    <Grid container>
      <Grid sm={4} item style={{ borderRight: '1px solid #312e6b' }}>
        <Box margin={3}>
          <Box marginBottom={2}>
            <Typography variant="h6">
              Playlister ({playlists?.length})
            </Typography>
          </Box>

          <Box marginBottom={2}>
            <Grid container>
              <Grid xs={6} item>
                <SearchField
                  searchPhrase={searchPhrase}
                  setSearchPhrase={setSearchPhrase}
                />
              </Grid>
              <Grid xs={6} item>
                <SortByField sortBy={sortBy} setSortBy={setSortBy} />
              </Grid>
            </Grid>
          </Box>

          <Box marginRight="10px" marginBottom={5}>
            <Button
              color="primary"
              to="/playlists/create"
              component={Link}
              variant="contained"
              fullWidth
            >
              Opret ny playlist
            </Button>
          </Box>
        </Box>

        <div
          style={{
            height: `calc(100vh - ${heightOffset}px)`,
            margin: '0 10px 27px 0',
            overflowY: 'scroll',
            padding: '0 0 0 16px'
          }}
        >
          <Box display="flex" flexWrap="wrap">
            {isLoading && (
              <Box display="flex" justifyContent="center" flex={1}>
                <CircularProgress size={50} />
              </Box>
            )}

            {playlists?.map((playlist) => (
              <LazyLoad
                once
                overflow
                throttle={100}
                height={30}
                style={{
                  padding: '0px 5px 0px 0px',
                  margin: '0 10px 10px 0',
                  width: '100%'
                }}
                key={playlist.id}
              >
                <PlaylistListItem
                  playlist={playlist}
                  key={playlist.id}
                  Draggable={Draggable}
                />
              </LazyLoad>
            ))}
          </Box>
        </div>
      </Grid>
      <Grid sm={8} item>
        {/* Render dummy FullCalendar to avoid Webpack treeshaking Fullcalendar out */}
        {/* and thereby breaking the Draggable interaction used by the playlist card */}
        <div style={{ display: 'none' }}>
          <FullCalendar
            schedulerLicenseKey={FULLCALENDAR_LICENSE_KEY}
            plugins={[resourceTimelinePlugin]}
            initialView="resourceTimelineMonth"
            headerToolbar={false}
          />
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    marginRight: 10,
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    flex: 1
  }
}));

interface SearchFieldProps {
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchPhrase,
  setSearchPhrase
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Søg"
        onChange={handleChange}
        defaultValue={searchPhrase}
        type="search"
      />
      <SearchIcon />
    </Paper>
  );
};

interface SortByFieldProps {
  sortBy: string;
  setSortBy: (value: 'id' | 'name') => void;
}

const SortByField: React.FC<SortByFieldProps> = ({ sortBy, setSortBy }) => {
  const classes = useStyles();
  const options = [
    { value: 'id', label: 'Vis nyeste først' },
    { value: 'name', label: 'Vis i alfabetisk rækkefølge' }
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value === 'name' ? 'name' : 'id');
  };

  return (
    <Paper className={classes.root}>
      <TextField
        select
        value={sortBy}
        onChange={handleChange}
        fullWidth
        InputProps={{
          disableUnderline: true
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Paper>
  );
};

export default PlaylistList;
