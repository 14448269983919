export const API_URL = `${process.env.REACT_APP_API_URL}system/`;
export const FULLCALENDAR_LICENSE_KEY =
  process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY;

export enum QUERY_KEYS {
  LOCALIZED_DATA = 'LOCALIZED_DATA',
  SIGN_IN = 'SIGN_IN',
  CHANNELS = 'CHANNELS',
  MEDIA_ITEMS = 'MEDIA_ITEMS',
  PLAYLISTS = 'PLAYLISTS',
  INSERTIONS = 'INSERTIONS'
}
