import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Paper,
  makeStyles,
  Theme,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { Insertion, Timeslot } from '../../interfaces';
import React, { useContext, useState } from 'react';
import { DialogContext } from '../../contexts/DialogContext';
import { useDeleteInsertion, useEditInsertion } from 'api/useInsertion';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import dayjsDa from 'dayjs/locale/da';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ClearIcon from '@material-ui/icons/Clear';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  editPlaylistIcon: {
    position: 'absolute',
    marginLeft: 5,
    marginTop: -2
  },
  inputWrapper: {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '5px 9px',
    position: 'relative'
  },
  inputIcon: {
    cursor: 'pointer',
    right: 8,
    opacity: 0.5,
    position: 'absolute',
    top: 22
  }
}));

interface InsertionEditProps {
  insertion: Insertion;
  timeslots: Timeslot[];
}

const InsertionEdit: React.FC<InsertionEditProps> = ({
  insertion,
  timeslots
}) => {
  dayjs.locale({ ...dayjsDa });
  const classes = useStyles();
  let history = useHistory();
  const { editInsertion } = useEditInsertion();
  const { closeDialog } = useContext(DialogContext);
  const { deleteInsertion } = useDeleteInsertion();
  const [startDate, handleStartDateChange] = useState<Date>(
    dayjs(insertion.start_date).toDate()
  );
  const [endDate, handleEndDateChange] = useState<Date | null>(
    insertion.end_date
      ? dayjs(insertion.end_date).subtract(1, 'day').toDate()
      : null
  );
  const [selectedTimeslotId, setSelectedTimeslotId] = React.useState<
    number | null
  >(insertion.timeslot_id);
  const [isLoading, setIsLoading] = useState<
    undefined | 'deleting' | 'saving'
  >();

  const handleTimeslotChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectedTimeslotId(checked ? +event.target.value : null);
  };

  const handleSaveClick = async () => {
    const timeslot = timeslots.find((x) => x.id === selectedTimeslotId);
    if (timeslot) {
      setIsLoading('saving');
      await editInsertion(
        insertion.playlist_id,
        insertion.id,
        startDate,
        endDate,
        timeslot
      );
      closeDialog();
    }
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Ønsker du at fjerne indrykningen?')) {
      setIsLoading('deleting');
      await deleteInsertion(insertion.id);
      closeDialog();
    }
  };

  const handleEditPlaylistClick = () => {
    closeDialog(() => history.push(`/playlists/edit/${insertion.playlist_id}`));
  };

  return (
    <React.Fragment>
      <Box flex="1" margin={2} marginTop={2} marginBottom={0}>
        <Box marginBottom={3}>
          <Typography variant="h3" align="center">
            {insertion.title}

            <Tooltip title="Rediger playlist">
              <IconButton
                size="small"
                className={classes.editPlaylistIcon}
                onClick={handleEditPlaylistClick}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>

        <Box marginBottom={4}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Paper className={classes.inputWrapper}>
                <DatePicker
                  label="Start dato"
                  fullWidth
                  maxDate={endDate}
                  disableToolbar
                  variant="inline"
                  autoOk
                  value={startDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    if (date) {
                      handleStartDateChange(date);
                    }
                  }}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.inputWrapper}>
                <DatePicker
                  label="Slut dato"
                  fullWidth
                  minDate={startDate}
                  disableToolbar
                  variant="inline"
                  autoOk
                  value={endDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    handleEndDateChange(date);
                  }}
                />
                <IconButton
                  className={classes.inputIcon}
                  size="small"
                  onClick={() => handleEndDateChange(null)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tidsintervaller</FormLabel>
            <FormGroup>
              {timeslots.map((timeslot) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={timeslot.id === selectedTimeslotId}
                      onChange={handleTimeslotChange}
                      value={timeslot.id}
                    />
                  }
                  label={timeslot.title}
                  key={timeslot.id}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>

      <Box margin={2}>
        <ButtonGroup fullWidth>
          <Button
            disabled={!!isLoading}
            color="secondary"
            variant="outlined"
            fullWidth
            onClick={handleDeleteClick}
          >
            {isLoading === 'deleting' ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Fjern indrykning'
            )}
          </Button>
          <Button
            disabled={!!isLoading}
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSaveClick}
          >
            {isLoading === 'saving' ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Gem indrykning'
            )}
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  );
};

export default InsertionEdit;
