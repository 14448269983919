import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import React, { useContext, useRef } from 'react';
import { API_URL, QUERY_KEYS } from '../../constants/constants';
import { AuthContext, DataContext } from '../../contexts';
import { useQueryClient } from 'react-query';
import { Box, Typography } from '@material-ui/core';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const MediaUploadDialog: React.FC = () => {
  const filePondRef = useRef<FilePond>(null);
  const { setLastUpdatedMedia } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return (
    <Box margin={2}>
      <Box marginBottom={2}>
        <Typography variant="h3" gutterBottom>
          Upload filer
        </Typography>
        <Typography variant="body2">
          Du kan uploade en eller flere billed- eller videofiler ad gangen.
        </Typography>
      </Box>

      <FilePond
        ref={filePondRef}
        allowMultiple={true}
        allowRevert={false}
        allowFileSizeValidation
        maxFileSize="200mb"
        allowFileTypeValidation
        acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'video/*']}
        name="file"
        maxFiles={10}
        onprocessfiles={() => {}}
        server={{
          process: function (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            formData.append('name', file.name);

            const request = new XMLHttpRequest();
            const url = `${API_URL}upload.php?clientname=${user?.clientname}&context=media&un=${user?.username}&pw=${user?.password}&location_id=`;
            request.open('POST', url);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
              let json: any = {};
              try {
                json = JSON.parse(request.responseText);
              } catch (error) {
                console.error('Filepond', error);
              }

              if (
                request.status >= 200 &&
                request.status < 300 &&
                !json.error
              ) {
                // the load method accepts either a string (id) or an object
                load(json.result.filename);
                setLastUpdatedMedia({
                  name: json.result.filename
                });
                queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
              } else {
                // Can call the error method if something is wrong, should exit after
                if (json.error) {
                  error(json.error.message + ' (' + json.error.code + ')');
                  console.error(
                    'Filepond',
                    json.error.message,
                    json.error.code
                  );
                } else {
                  error('oh no');
                }
              }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
              abort: () => {
                // This function is entered if the user has tapped the cancel button
                request.abort();

                // Let FilePond know the request has been cancelled
                abort();
              }
            };
          }
        }}
      />
    </Box>
  );
};

export default MediaUploadDialog;
