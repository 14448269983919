import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
//import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from './theme/theme';
import AuthContextProvider from './contexts/AuthContext';
import DialogContextProvider from './contexts/DialogContext';
import { SnackProvider } from './components/Snackbar/Snackbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import daLocale from 'date-fns/locale/da';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount) => failureCount < 2,
      staleTime: 60 * 1000 // 1 minute
    }
  }
});
const theme = createTheme();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <SnackProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
            <DialogContextProvider>
              <App />
            </DialogContextProvider>
          </MuiPickersUtilsProvider>
        </SnackProvider>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
