import { objectToArray } from 'helpers/arrayHelper';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { SnackbarContext } from '../components/Snackbar/Snackbar';
import { QUERY_KEYS } from '../constants/constants';
import { AuthContext, DataContext, DialogContext } from '../contexts';
import { MediaItem, TranscodeMediaResult } from '../interfaces';
import { querystring2json } from '../lib/querystring-parser';
import { xml2json } from '../lib/xml-parser';
import { objToFormData } from './formData';
import { useFetcher } from './useFetcher';

export const useGetMediaItems = () => {
  const { user } = useContext(AuthContext);
  const { fetcher } = useFetcher();

  const getMediaItems = async (): Promise<MediaItem[]> => {
    const result = await fetcher(
      `get_library.php?location_id=&include_candidates=true&uid=${user?.username}`
    );

    return new Promise((resolve) => {
      const json = xml2json(result, 'name');
      let items = json?.media?.item || [];

      // The xml2json parser will return either an object or an array of objects.
      // Let's make it easy and put a single object into an array.
      items = objectToArray(items);
      items =
        items.map((item: MediaItem, index: number) => ({
          ...item,
          index
        })) || [];

      // The xml2json parser will return either an object or an array of objects.
      // Let's make it easy and put a single object into an array.
      items = objectToArray(items);

      resolve(items);
    });
  };
  return { getMediaItems };
};

export const useTranscodeMedia = () => {
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);

  const transcodeMedia = async (
    item: MediaItem
  ): Promise<TranscodeMediaResult> => {
    return new Promise(async (resolve, reject) => {
      const response = await fetcher(`transcode.php?source_url=${item.source}`);
      if (response.startsWith('result=true')) {
        const result = querystring2json(response) as TranscodeMediaResult;
        result.output_url = decodeURIComponent(result.output_url);
        resolve(result);
        return;
      }

      const error = querystring2json(response);
      createSnack(`Activate media failed! [${error.msg}]`, {
        severity: 'error'
      });
      reject();
    });
  };

  return { transcodeMedia };
};

export const useGenerateThumbnail = () => {
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);
  const { saveMedia } = useEditMedia();

  const generateThumbnail = async (
    item: MediaItem,
    previewUrl: string = '',
    delay: number = 0
  ): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const response = await fetcher(
        `transcode_thumb.php?source_url=${item.source}&delay=${delay}`
      );

      if (response.startsWith('result=true')) {
        const result = querystring2json(response) as TranscodeMediaResult;
        result.output_url = decodeURIComponent(result.output_url);

        const saved = await saveMedia(item, result.output_url, previewUrl);
        if (saved) {
          createSnack('Mediet er aktiveret!', {});
          resolve(true);
          return;
        }
      }

      const error = querystring2json(response);
      createSnack(`Generate thumbnail failed! [${error.msg}]`, {
        severity: 'error'
      });
      reject(false);
    });
  };

  return { generateThumbnail };
};

export const useEditMedia = () => {
  const { fetcher } = useFetcher();
  const { setLastUpdatedMedia } = useContext(DataContext);
  const { createSnack } = useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const saveMedia = async (
    item: MediaItem,
    thumbUrl: string,
    movieUrl: string = ''
  ): Promise<boolean> => {
    const formData = objToFormData({
      media_title: item.name,
      media_locked: 'off',
      media_thumb: thumbUrl,
      media_movie: movieUrl,
      media_source: item.source
    });

    // When updating an existing media we have an ID,
    // not so when creating a new media.
    if (item.id) {
      formData.append('media_id', item.id.toString());
    }

    if (item.name.endsWith('.png') || item.name.endsWith('.jpg')) {
      formData.append('media_duration', (item.duration || 10.0).toString());
      formData.append('media_type', 'image');
    } else {
      formData.append('media_type', 'movie');
    }

    const response = await fetcher('edit_media.php', {
      method: 'POST',
      body: formData
    });

    return new Promise(async (resolve, reject) => {
      if (response.startsWith('result=true')) {
        // When activating a new media item, an id is returned.
        // We need that id to identify the media with setLastUpdatedMedia.
        const result = querystring2json(response);
        const mediaId = parseInt(result.new_item_id) || item.id;

        queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
        setLastUpdatedMedia({ id: mediaId });
        resolve(true);
        return;
      }

      const error = querystring2json(response);
      createSnack(`Save media failed! [${error.msg}]`, { severity: 'error' });
      reject();
    });
  };

  const saveMediaName = async (
    item: MediaItem,
    name: string,
    setName: (value: string) => void
  ): Promise<boolean> => {
    const formData = objToFormData({
      media_id: item.id,
      media_title: name
    });

    const response = await fetcher('edit_media.php', {
      method: 'POST',
      body: formData
    });

    return new Promise(async (resolve, reject) => {
      if (response.startsWith('result=true')) {
        queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
        setName(name);
        createSnack('Navnet blev gemt.', {});
        setLastUpdatedMedia(item);
        resolve(true);
        return;
      }

      const error = querystring2json(response);
      createSnack(`Save media name failed! [${error.msg}]`, {
        severity: 'error'
      });
      reject();
    });
  };

  const saveMediaDuration = async (
    item: MediaItem,
    duration: string,
    setDuration: (value: number) => void
  ): Promise<boolean> => {
    const parsed = parseFloat(duration);

    if (isNaN(parsed)) {
      createSnack('Den indtastede varighed skal være et tal!', {
        severity: 'error'
      });
      return false;
    }

    const formData = objToFormData({
      media_id: item.id,
      media_duration: duration
    });

    const response = await fetcher('edit_media.php', {
      method: 'POST',
      body: formData
    });

    return new Promise(async (resolve, reject) => {
      if (response.startsWith('result=true')) {
        queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
        setDuration(parsed);
        createSnack('Varigheden blev gemt.', {});
        setLastUpdatedMedia(item);
        resolve(true);
        return;
      }

      const error = querystring2json(response);
      createSnack(`Save media duration failed! [${error.msg}]`, {
        severity: 'error'
      });
      reject();
    });
  };

  return { saveMedia, saveMediaName, saveMediaDuration };
};

export const useDeleteMedia = () => {
  const { fetcher } = useFetcher();
  const { setLastUpdatedMedia } = useContext(DataContext);
  const { createSnack } = useContext(SnackbarContext);
  const { closeDialog } = useContext(DialogContext);
  const queryClient = useQueryClient();

  const deleteMedia = async (item: MediaItem): Promise<void> => {
    let url;
    let formData;

    if (item.id) {
      url = 'remove_media.php';
      formData = objToFormData({
        'media_items[0]': item.id
      });
    } else {
      url = 'remove_file.php';
      formData = objToFormData({
        context: 'media',
        src: item.source
      });
    }

    const response = await fetcher(url, {
      method: 'POST',
      body: formData
    });

    return new Promise((resolve, reject) => {
      if (response.startsWith('result=true')) {
        queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
        createSnack('Filen blev slettet.', {});
        setLastUpdatedMedia(item);
        closeDialog();
        resolve();
        return;
      }

      const error = querystring2json(response);
      createSnack(`Delete media failed! [${error.msg}]`, { severity: 'error' });
      reject();
    });
  };

  return { deleteMedia };
};
