import React, { createContext, useContext, useState } from 'react';
import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface DialogContextProps {
  component: JSX.Element | null;
  openDialog: (component: JSX.Element | null) => void;
  closeDialog: (callback?: () => void) => void;
  callbackOnClose: (() => void) | null;
  setCallbackOnClose: (callback: () => void) => void;
}

const initialValue = {
  component: null,
  openDialog: () => {},
  closeDialog: () => {},
  callbackOnClose: null,
  setCallbackOnClose: () => {}
};



export const DialogContext = createContext<DialogContextProps>(initialValue);

export default function DialogContextProvider({ children }: { children: any }) {
  const [component, openDialog] = useState<JSX.Element | null>(null);
  const [callbackOnClose, __setCallbackOnClose] = useState<(() => void) | null>(
    null
  );

  const closeDialog = (callback?: () => void) => {
    openDialog(null);

    if (callbackOnClose) {
      callbackOnClose();
      __setCallbackOnClose(null);
    }

    if (callback) {
      callback();
    }
  };

  const setCallbackOnClose = (callback: () => void) => {
    __setCallbackOnClose(() => callback);
  };

  return (
    <DialogContext.Provider
      value={{
        component,
        openDialog,
        closeDialog,
        callbackOnClose,
        setCallbackOnClose
      }}
    >
      {children}
    </DialogContext.Provider>
  );
}

export const RenderDialog: React.FC = ({ children = null }): JSX.Element => {
  const { closeDialog } = useContext(DialogContext);
  const handleCloseClick = () => closeDialog();

  return (
    <Dialog
      onClose={handleCloseClick}
      open={!!children}
      PaperProps={{ style: { width: 500 } }}
      BackdropProps={{}}
    >
      <Tooltip title="Luk">
        <IconButton
          size="small"
          onClick={handleCloseClick}
          style={{
            //border: "4px solid gray", //no border or fill by default
            //borderRadius: "4px", //try "0px"
            backgroundColor: "rgba(180,180,180,.2)",
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 1
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>

      {children}
    </Dialog>
  );
};
